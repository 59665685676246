<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="auto_fix_FILL1_wght400_GRAD0_opsz48 1">
      <path
        id="Vector"
        d="M20.6232 6.42498L19.7732 4.54998L17.8982 3.69998L19.7732 2.84998L20.6232 0.974976L21.4732 2.84998L23.3482 3.69998L21.4732 4.54998L20.6232 6.42498ZM8.5732 6.42498L7.7232 4.54998L5.8482 3.69998L7.7232 2.84998L8.5732 0.974976L9.4232 2.84998L11.2982 3.69998L9.4232 4.54998L8.5732 6.42498ZM20.6232 18.475L19.7732 16.6L17.8982 15.75L19.7732 14.9L20.6232 13.025L21.4732 14.9L23.3482 15.75L21.4732 16.6L20.6232 18.475ZM4.9982 21.7L2.6232 19.325C2.43986 19.1416 2.33986 18.9291 2.3232 18.6875C2.30653 18.4458 2.40653 18.2166 2.6232 18L13.8732 6.74998C14.0732 6.54998 14.3149 6.44998 14.5982 6.44998C14.8815 6.44998 15.1232 6.54998 15.3232 6.74998L17.5732 8.99998C17.7732 9.19998 17.8732 9.44164 17.8732 9.72498C17.8732 10.0083 17.7732 10.25 17.5732 10.45L6.3232 21.7C6.1232 21.9 5.90236 22 5.6607 22C5.41903 22 5.1982 21.9 4.9982 21.7ZM14.5732 11.3L16.1232 9.74998L14.5732 8.19998L13.0232 9.74998L14.5732 11.3Z"
        :fill="color"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ic-auto-fix',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    color: {
      type: String,
    },
  },
}
</script>

<style scoped></style>
